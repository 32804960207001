import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/111/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA® Cellular Boost Koncentrované sérum proti vráskám
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-Cellular-Boost-SERUM-Crtn-30-3D2.jpg"
                      alt="NTG Cellular Boost SERUM Crtn 30 3D2"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA® Cellular Boost Koncentrované sérum proti
                      vráskám
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          NOVINKA
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          &nbsp;
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            "font-size": "large"
                          }}
                        >
                          NEUTROGENA® Cellular Boost Koncentrované sérum proti
                          vráskám
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>
                        Vyhlazuje nejen jemné linky, ale i hluboké vrásky,
                        způsobené buněčným vyhořením.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Buněčné vyhoření zpomaluje procesy v buňkách pokožky a
                        je tak jednou z hlavních příčin stárnutí pleti.
                        Důsledkem je ztráta elasticity, vznik vrásek a
                        nejednotný tón pleti. Příčinami buněčného vyhoření jsou
                        zejména UV záření, znečištění ovzduší, stres a únava.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Klinicky prověřené složení séra obsahuje dvě z
                        nejznámějších omlazujících ingrediencí:
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        - <strong>Retinol</strong>, který stimuluje obnovu buněk
                        a zvyšuje produkci vlastního kolagenu a elastinu*
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        - <strong>Kyselinu hyaluronovou</strong>, &nbsp;která
                        &nbsp;pomáhá &nbsp;obnovit ochrannou bariéru pleti a
                        vyplnit vrásky
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p>
                        <span
                          style={{
                            "font-size": "x-small"
                          }}
                        >
                          Klinicky ověřeno. Pro dosažení nejlepších výsledků
                          používejte každý den. Nekomedogenní. *Testováno
                          laboratorně.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p className="TableParagraph">
                        Řada NEUTROGENA® Cellular Boost byla vyvinuta ve
                        spolupráci s dermatology. Unikátní složení zmírňuje
                        viditelné známky stárnutí, způsobené buněčným vyhořením.
                        Vyhlazuje vrásky, viditelně zlepšuje elasticitu a tón
                        pleti a navrací jí energii. Uchová mladistvý vzhled Vaší
                        pleti po delší dobu.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Objevte celou řadu NEUTROGENA®&nbsp;Cellular&nbsp; Boost
                        pro kompletní péči o Vaši pleť.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Aplikujte jednou denně, ráno nebo večer na důkladně
                      vyčištěnou pokožku obličeje a krku. Jemně vmasírujte
                      krouživými pohyby. UPOZORNĚNÍ: Přizpůsobte frekvenci
                      používání citlivosti Vaší pleti. V případě nežádoucí
                      reakce přestaňte sérum používat. Vyhněte se oblasti kolem
                      očí. Používejte ochranu proti slunci, obsažený retinol
                      může zvýšit citlivost na sluneční záření.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>30 ml</dd>
                  </dl>
                </div>
                <div id="fb_59"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/100/"
                  >
                    <img
                      src="/assets/Uploads/NTG-HydroBoost-TMW-400mlv.jpg"
                      alt="NTG HydroBoost TMW 400mlv"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/102/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-WashMask-150ml-bez-stinu.jpg"
                      alt="NTG SkinDetox WashMask 150ml bez stinu"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>
                        <sup>&nbsp; </sup>Skin Detox 2v1 Čistící emulze a maska
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/103/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-TMW-400ml-002f.jpg"
                      alt="NTG SkinDetox TMW 400ml 002f"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/106/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-Peeling-150ml-bez-stinu2.jpg"
                      alt="NTG SkinDetox Peeling 150ml bez stinu2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Pleťový peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/107/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-krem-2v1-50-ml2.jpg"
                      alt="NTG SkinDetox krem 2v1 50 ml2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>
                        <sup>&nbsp; </sup>Skin Detox Hydratační krém 2v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
